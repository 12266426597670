import React from "react";

// Customizable Area Start
import { createRef } from "react";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  Avatar, 
  Badge,
  TextareaAutosize,
  Link,
  CircularProgress
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import SendIcon from '@material-ui/icons/Send';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CloseIcon from '@material-ui/icons/Close';
import EmojiPicker from 'emoji-picker-react';  
import { getStorageData } from '../../../framework/src/Utilities';


// Customizable Area End

import ChatController, { Props, Messages, S} from "./ChatController";

// Customizable Area Start
// Customizable Area End
export default class LiveChat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  private fileInputRef = React.createRef<HTMLInputElement>();

  handleFileButtonClick = () => {
    this.fileInputRef.current?.click();
  };

  toggleChat = async () => {
    const userId = await getStorageData('userId');
    const chatRoomId = await getStorageData('chatRoomId')
    if (this.state.isOpen) {
      this.disconnectSocket();
    }

    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (this.state.isOpen) {
          this.scrollToLatestMessage();
        }
      }
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isOpen, adminOnline, messages, newMessage, isEmojiPickerVisible, isLoadingMessages } = this.state;
    let lastDate = "";
    return (
      <>
        <ChatButton
          onClick={this.toggleChat}
          data-testid="toggle-chat-button"
          color="primary"
          style={{
            height: "50px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: isOpen ? "none" : "block",
          }}
        >
          <ChatButtonText style={{ fontWeight: "700" }}>
            Live Chat
          </ChatButtonText>
          <ChatButtonIcon style={{ color: "#FFFFFF", height: "24px", width: "24px" }} />
        </ChatButton>

        {isOpen && (
          <ChatContainer elevation={3} style={{ borderRadius: "20px" }}>
            <style>
              {` 
                .MuiBadge-colorSecondary {
                  background-color: green !important;
                }
                .MuiBadge-colorPrimary {
                  background-color: grey !important;
                }  
              `
              }
            </style>
            <Box
              style={{
                backgroundColor: "#019F48",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "55px",
                borderRadius: "20px",
                zIndex: 10,
                position: 'relative'
              }}
            >
              <Typography style={{ fontSize: "27px", fontWeight: "700", fontFamily: "'Inter', sans-serif"  }}>Live Chat</Typography>
              <IconButton
                style={{ color: "#fff", height: "32px", width: "32px", position: 'absolute', top: '9px', right: '5px'}}
                onClick={this.toggleChat}
                data-testid="close-button"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <AdminStatus data-testid="admin-status">
              <BackButton>
                <ArrowBackIosIcon />
              </BackButton>
              <Badge
                variant="dot"
                color={adminOnline ? "secondary" : "primary"}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                overlap="circular"
                className="badge"
              >
                <Avatar style={{ backgroundColor: "#ccc", height: "32px", width: "32px" }} />
              </Badge>
              <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "8px" }}>
                <Typography style={{ fontWeight: "600", color: "black", fontSize: "14px",  fontFamily: "Corbel, sans-serif" }}>Admin</Typography>
                <Typography style={{ color: '#059669', fontSize: "9px", fontWeight: "400",  fontFamily: "Corbel, sans-serif"  }}>
                  {adminOnline ? "Online" : "Offline"}
                </Typography>
              </Box>
            </AdminStatus>

            <ChatMessages ref={this.chatContainerRef} onScroll={this.handleScroll}>
              <div id="load-more">
                {messages.map((message: Messages, index) => {

                  const messageDate = this.formatDate(message.timestamp);
                  const isNewDate = index === 0 || this.formatDate(messages[index - 1].timestamp) !== messageDate;

                  return (
                    <>
                      {isNewDate && (
                        <Box style={{ display: "flex", justifyContent: "center", padding: "8px 0px" }}>
                          <Typography
                            variant="caption"
                            style={{
                              color: "#37657F",
                              fontSize: "12px",
                              fontWeight: "400",
                              fontStyle: "italic",
                              fontFamily: "Corbel, sans-serif"
                            }}
                          >
                            {this.getDateLabel(message.timestamp)}
                          </Typography>
                        </Box>
                      )}

                      <MessageContainer
                        key={message.id}
                        style={{
                          flexDirection: message.sender === "user" ? "row-reverse" : "row",
                        }}
                      >
                        <MessageBubble
                          style={{
                            backgroundColor:
                              message.sender === "admin" ? "#F1F5F9" : "#019F48",
                            color: message.sender === "admin" ? '' : '#FFFFFF',
                          }}
                        >
                          {message.message &&
                            <Typography variant="body2" style={{ fontSize: "12px", fontWeight: "400", fontFamily: "Corbel, sans-serif", color: message.sender === "admin" ? '#000000' : '#FFFFFF', }}>
                              {message.message}
                            </Typography>
                          }

                          {Array.isArray(message.file) &&
                            message.file.length > 0 &&
                            message.file.map((fileItem: { url: string }, index: number) => {
                              return (
                                <Link
                                data-testid={`file-link-${index}`}
                                  key={index}
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Corbel, sans-serif",
                                    color: message.sender === "admin" ? "#000000" : "#FFFFFF",
                                    display: "block",
                                  }}
                                  href={fileItem.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {fileItem.url.split('/').pop()} 
                                </Link>
                              );
                            })}


                          <Typography
                            style={{
                              display: "block",
                              marginTop: 10,
                              color: message.sender === "admin" ? '#059669' : '#FFFFFF',
                              fontSize: "10px",
                              fontWeight: "400",
                              fontStyle: "italic",
                              textAlign: "end",
                              fontFamily: "Corbel, sans-serif"
                            }}
                          >
                            {this.formatTime(message.timestamp)}
                          </Typography>
                        </MessageBubble>
                      </MessageContainer>
                    </>
                  );
                })}
                <div ref={this.messagesEndRef} />
                {this.state.isLoadingMessages && <div style={{display: 'flex', justifyContent: 'center'}}> <CircularProgress value={10} size={20}  style={{ color: '#059669' }}  /></div>}
              </div>
            </ChatMessages>

            <ChatInputContainer>
              <input
                type="file"
                ref={this.fileInputRef}
                style={{ display: "none" }}
                onChange={this.handleFileChange}
                multiple
              />
              <IconButton
                data-testid="file-input-button"
                style={{ color: "#019F48", height: "32px", width: "32px" }}
                onClick={this.handleFileButtonClick}
              >
                <AttachFileIcon />
              </IconButton>

              <IconButton
                style={{ color: "#019F48", height: "32px", width: "32px" }}
                onClick={this.toggleEmojiPicker}
              >
                <EmojiEmotionsIcon />
              </IconButton>
              {isEmojiPickerVisible && (
                <div style={{ position: "absolute", bottom: "80px", left: "5px", zIndex: 333333 }}>
                  <EmojiPicker style={{height: '280px', width: '300px'}} onEmojiClick={this.handleEmojiClick} data-testid='emoji-picker'/>
                </div>
              )}
              <StyledTextarea
                minRows={1}
                maxRows={1}
                placeholder="Type here"
                value={newMessage}
                onChange={this.handleInputChange}
              />
              <SendButton
              data-testid="send-button"
                 onClick={this.handleSend}
                 disabled={!newMessage.trim()}
              >

                <SendIcon />
              </SendButton>
            </ChatInputContainer>
          </ChatContainer>        
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ChatButton = styled(IconButton)({
  "@media only screen and (max-width: 767px)" : {
    width: '50px',
    borderRadius: 25,
},
width: '145px',

})

const ChatButtonIcon = styled(ChatBubbleOutlineIcon)({
  "@media only screen and (max-width: 767px)" : {
    paddingLeft: '0',
    bottom: '20px'
  },
paddingLeft: '8px',

})

const ChatButtonText = styled(Typography)({
  "@media only screen and (max-width: 767px)" : {
  display: 'none'
},
color: "#FFFFFF", 
fontSize: "15px", 
fontFamily: "'Inter', sans-serif", 
display: "block",
})

const ChatContainer = styled(Paper)({
  "@media only screen and (max-width: 767px)" : {
      right: 15,
      height: "calc(100vh - 90px)", 
      width: "calc(100vw - 30px)",

  },
  width: "calc(100vw - 65%)",
  height: "calc(100vh - 30%)",  
  position: "fixed",
  bottom: 10,
  right: 30,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  zIndex: 222222,
  padding: 2,
});

const AdminStatus = styled(Box)({
  height: 55,
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  padding: "16px 8px",
});

const BackButton = styled(IconButton)({
  color: '#37657F',
  height: '24px',
  width: '24px',
});

const ChatMessages = styled('div')({
  padding: 8,
  paddingTop: 0,
  flex: 1,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
});

const MessageContainer = styled(Box)({
  display: "flex",
  padding: '5px',
  marginBottom: 1,
});

const MessageBubble = styled(Paper)({
  padding: 8,
  maxWidth: "80%",
  color: "#000",
  borderRadius: 5,
  wordWrap: "break-word",
});

const ChatInputContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 8,
  border: "1px solid #ddd",
  height: '40px',
  margin: '8px',
  marginBottom: '16px',
  borderRadius: '8px',
});

const StyledTextarea = styled(TextareaAutosize)({
  flex: 1,
  resize: "none",
  overflowY: "auto",
  paddingLeft: "10px",
  fontSize: "16px",
  fontWeight: 400,
  marginLeft: 1,
  color: "#64748B",
  maxHeight: "50px",
  outline: "none",
  border: "none",
  fontFamily: "Corbel, sans-serif" 
});

const SendButton = styled(IconButton)({
  color: '#37657F',
  height: "44px",
  width: "44px",
  backgroundColor: '#E2E8F0',
  borderRadius: '8px',
});
// Customizable Area End
